@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  color: #181c32;
  color: var(--default-500-dark);
  background-color: #f0f3f7;
  background-color: var(--background-1);
}

body::-webkit-scrollbar {
  display: none;
}

.ant-scrolling-effect {
  width: 100% !important;
}

* {
  box-sizing: border-box;
}

*,
*:focus,
*:hover {
  outline: none;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0;
}

#root {
  height: 100%;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
}

h3 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
}

h4 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
}

h5 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

h6 {
  font-size: 18px;
  font-weight: bold;
}

.ant-checkbox-inner {
  border-radius: 2px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid #ffffff;
  border: 1px solid var(--default-500-light);
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(40deg) scale(1) translate(-60%, -60%);
          transform: rotate(40deg) scale(1) translate(-60%, -60%);
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #8f9bb3;
  border-color: var(--basic-fill-06-600);
}

.message .ant-message-notice-content {
  font-size: 18px;
  font-weight: bold;
  max-width: 90%;
  width: auto;
  min-height: 56px;
  height: auto;
  text-align: start;
  color: #ffffff;
  color: var(--default-500-light);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .message .ant-message-notice-content {
    width: 320px;
    max-width: 600px;
    min-width: 260px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .message .ant-message-notice-content {
    max-width: 440px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.message .ant-message-custom-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.message .ant-message-custom-content span {
  word-break: break-all;
}

.message_success .ant-message-notice-content {
  background-color: #00b383;
  background-color: var(--color-fill-2-success-600);
}

.message_error .ant-message-notice-content {
  background-color: #db3442;
  background-color: var(--color-fill-5-danger-600);
}

.message_warning .ant-message-notice-content {
  background-color: #ffa800;
  background-color: var(--color-fill-4-warning-500);
}

.message svg {
  -webkit-align-items: center;
          align-items: center;
  margin-right: 13px;
}

.ant-picker-cell-in-view.ant-picker-cell-today {
  color: #3266ff;
  color: var(--default-500-primary);
}

.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 2px;
}

.ant-picker-next-icon,
.ant-picker-super-next-icon,
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  -webkit-transform: none;
          transform: none;
}

.ant-picker .ant-picker-suffix svg {
  width: 14px;
  height: 15px;
}

.ant-picker-super-next-icon,
.ant-picker-super-prev-icon {
  width: 22px;
  height: 22px;
}

.ant-picker-super-next-icon {
  background: url(/static/media/icon-his-icons-arrowhead-right.3145fcd9.svg);
  background-size: contain;
}

.ant-picker-super-prev-icon {
  background: url(/static/media/icon-his-icons-arrowhead-left.4fe04506.svg);
  background-size: contain;
}

.ant-picker-next-icon,
.ant-picker-prev-icon {
  width: 15px;
  height: 15px;
}

.ant-picker-next-icon {
  background: url(/static/media/arrow-ios-right-outline-thick.d1d8b7c8.svg);
  background-size: contain;
}

.ant-picker-prev-icon {
  background: url(/static/media/arrow-ios-left-outline-thick.e9a913a1.svg);
  background-size: contain;
}

.ant-picker-header > button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before,
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  display: none;
}

.ant-picker-header,
.ant-range-picker--calendar-panel .ant-picker-footer {
  border-bottom: 1px solid #edf1f7;
  border-bottom: 1px solid var(--basic-fill-03-300);
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #edf1f7;
  border-top: 1px solid var(--basic-fill-03-300);
}

.ant-picker-panel-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ant-picker-panel-container .ant-picker-panels {
  -webkit-order: 2;
          order: 2;
}

.ant-picker-header-view,
.ant-picker-date-panel .ant-picker-content > thead th {
  font-weight: bold;
}

.ant-switch[aria-checked='false'] {
  background-color: #e2e6ee;
  background-color: var(--default-500-secondary);
}

.ant-switch-disabled[aria-checked='true'] {
  background-color: rgba(51, 102, 255, 0.4);
  background-color: var(--transparent-fill-1-primary-40);
}

.ant-switch-disabled[aria-checked='false'] {
  background-color: #e4e9f2;
  background-color: var(--basic-fill-04-400);
}

.ant-select .ant-select-arrow {
  width: 24px;
  height: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 4px;
  margin-top: 0;
}

.ant-select-dropdown svg {
  width: 100%;
  max-width: 100%;
}

.ant-select-dropdown .ant-select-item-option {
  -webkit-align-items: center;
          align-items: center;
}

.ant-select-dropdown .ant-select-item-option-state {
  width: 24px;
  height: 24px;
}

.datePicker__calendar-panel .rangePicker__shortcuts span {
  margin: 0 4px;
  padding: 2px 10px;
  border-radius: 34px;
  border: solid 1px #3266ff;
  border: solid 1px var(--default-500-primary);
  background-color: rgba(51, 102, 255, 0.08);
  background-color: var(--transparent-fill-1-primary-08);
  color: #3266ff;
  color: var(--default-500-primary);
  font-weight: 500;
}

.datePicker__calendar-panel .rangePicker__shortcuts span:first-of-type {
  margin-left: 0;
}

.comment-popover-overlay {
  width: 332px;
  max-width: 100%;
}

.comment-popover-overlay .ant-popover-arrow {
  display: none;
}

.comment-popover-overlay .ant-popover-title {
  border-bottom: none;
  padding: 12px;
}

.comment-popover-overlay .ant-popover-inner-content {
  padding: 0;
}

.ant-popover {
  z-index: 999;
}

:root {
  --border-radius: 8px;
  --primary-box-shadow: 0 15px 15px 0 rgba(50, 102, 255, 0.5);
  --info-box-shadow: 0 15px 15px 0 rgba(0, 149, 255, 0.5);
  --success-box-shadow: 0 15px 15px 0 rgba(0, 179, 131, 0.5);
  --danger-box-shadow: 0 15px 15px 0 rgba(246, 78, 95, 0.5);
  --warning-box-shadow: 0 15px 15px 0 rgba(254, 159, 67, 0.5);
  --scrollbar-thumb: #d8d8d8;
  --scrollbar-thumb-hover: #b0b0b0;
  --case-background: #edf0f5;
  --case-border: #dae1e7;
  --default-500-danger: #f64e5f;
  --default-500-success: #1cc5bc;
  --default-500-primary: #3266ff;
  --default-500-hint: #7e8299;
  --default-500-dark: #181c32;
  --default-500-secondary: #e2e6ee;
  --default-500-warning: #ffa800;
  --default-500-light: #ffffff;

  --light-100-primary: #e2f0fd;
  --light-100-secondary: #f4f6fa;
  --light-100-success: #c9f7f4;
  --light-100-danger: #fee2e4;
  --light-100-warning: #fff4dd;
  --light-100-dark: #d1d3e0;

  --dentall-blue: #00a1e1;
  --transparent-0: rgba(249, 249, 249, 0);
  --transparent-fill-2-basic-08: rgba(143, 155, 179, 0.08);
  --transparent-fill-2-basic-16: rgba(143, 155, 179, 0.16);
  --transparent-fill-2-basic-24: rgba(143, 155, 179, 0.24);
  --transparent-fill-2-basic-32: rgba(143, 155, 179, 0.32);
  --transparent-fill-2-basic-40: rgba(143, 155, 179, 0.4);
  --transparent-fill-2-basic-48: rgba(143, 155, 179, 0.48);

  --transparent-fill-3-success-08: rgba(0, 224, 150, 0.08);
  --transparent-fill-3-success-16: rgba(0, 224, 150, 0.16);
  --transparent-fill-3-success-24: rgba(0, 224, 150, 0.24);
  --transparent-fill-3-success-32: rgba(0, 224, 150, 0.32);
  --transparent-fill-3-success-40: rgba(0, 224, 150, 0.4);
  --transparent-fill-3-success-48: rgba(0, 224, 150, 0.48);

  --transparent-fill-5-warning-08: rgba(254, 159, 67, 0.08);
  --transparent-fill-5-warning-16: rgba(254, 159, 67, 0.16);
  --transparent-fill-5-warning-24: rgba(254, 159, 67, 0.24);
  --transparent-fill-5-warning-32: rgba(254, 159, 67, 0.32);
  --transparent-fill-5-warning-40: rgba(254, 159, 67, 0.4);
  --transparent-fill-5-warning-48: rgba(254, 159, 67, 0.48);

  --transparent-fill-6-danger-08: rgba(234, 84, 85, 0.08);
  --transparent-fill-6-danger-16: rgba(234, 84, 85, 0.16);
  --transparent-fill-6-danger-24: rgba(234, 84, 85, 0.24);
  --transparent-fill-6-danger-32: rgba(234, 84, 85, 0.32);
  --transparent-fill-6-danger-40: rgba(234, 84, 85, 0.4);
  --transparent-fill-6-danger-48: rgba(234, 84, 85, 0.48);

  --transparent-fill-7-white-08: rgba(255, 255, 255, 0.08);
  --transparent-fill-7-white-16: rgba(255, 255, 255, 0.16);
  --transparent-fill-7-white-24: rgba(255, 255, 255, 0.24);
  --transparent-fill-7-white-32: rgba(255, 255, 255, 0.32);
  --transparent-fill-7-white-40: rgba(255, 255, 255, 0.4);
  --transparent-fill-7-white-48: rgba(255, 255, 255, 0.48);

  --transparent-fill-1-primary-08: rgba(51, 102, 255, 0.08);
  --transparent-fill-1-primary-16: rgba(51, 102, 255, 0.16);
  --transparent-fill-1-primary-32: rgba(51, 102, 255, 0.32);
  --transparent-fill-1-primary-24: rgba(51, 102, 255, 0.24);
  --transparent-fill-1-primary-40: rgba(51, 102, 255, 0.4);
  --transparent-fill-1-primary-48: rgba(51, 102, 255, 0.48);

  --transparent-fill-4-info-08: rgb(0, 149, 255, 0.08);
  --transparent-fill-4-info-16: rgba(0, 149, 255, 0.16);
  --transparent-fill-4-info-32: rgba(0, 149, 255, 0.32);
  --transparent-fill-4-info-24: rgba(0, 149, 255, 0.24);
  --transparent-fill-4-info-40: rgba(0, 149, 255, 0.4);
  --transparent-fill-4-info-48: rgba(0, 149, 255, 0.48);

  --image-overlay-1-fill-1-dark: rgba(0, 0, 0, 0.35);
  --image-overlay-1-fill-4-super-dark: rgba(0, 0, 0, 0.75);
  --border-bottom: rgba(0, 0, 0, 0.06);
  --image-overlay-1-fill-2-white: rgba(255, 255, 255, 0.8);
  --image-overlay-1-fill-3-primary: rgba(51, 102, 255, 0.8);
  --disabled-color: rgba(126, 130, 153, 0.48);

  --color-fill-1-primary-400: #6590ff;
  --color-fill-2-success-100: #d4ffee;
  --color-fill-2-success-200: #b3ffd6;
  --color-fill-2-success-300: #8cfac7;
  --color-fill-2-success-400: #27e2a2;
  --color-fill-2-success-500: #00d59c;
  --color-fill-2-success-600: #00b383;
  --color-fill-2-success-700: #008f72;
  --color-fill-2-success-800: #007566;
  --color-fill-2-success-900: #00524c;
  --color-fill-3-info-100: #cfecff;
  --color-fill-3-info-200: #c7e2ff;
  --color-fill-3-info-300: #94cbff;
  --color-fill-3-info-400: #42aaff;
  --color-fill-3-info-500: #0095ff;
  --color-fill-3-info-600: #006fd6;
  --color-fill-3-info-700: #0057c2;
  --color-fill-3-info-800: #0041a8;
  --color-fill-3-info-900: #002885;
  --color-fill-4-warning-100: #fff4dd;
  --color-fill-4-warning-200: #fee4b3;
  --color-fill-4-warning-300: #fed08e;
  --color-fill-4-warning-400: #ffcb7e;
  --color-fill-4-warning-500: #ffa800;
  --color-fill-4-warning-600: #da7b30;
  --color-fill-4-warning-700: #b86e00;
  --color-fill-4-warning-800: #945400;
  --color-fill-4-warning-900: #774300;
  --color-fill-5-danger-100: #fee2e4;
  --color-fill-5-danger-200: #fcccbc;
  --color-fill-5-danger-300: #f8a999;
  --color-fill-5-danger-400-disable: #fd97a1;
  --color-fill-5-danger-500: #f64e5f;
  --color-fill-5-danger-600: #db3442;
  --color-fill-5-danger-700: #b7243c;
  --color-fill-5-danger-800: #871a37;
  --color-fill-5-danger-900: #701032;
  --color-fill-6-others-100-purple: #eae2fe;

  --basic-background: #f8fafb;
  --background-1: #f0f3f7;

  --basic-fill-02-200: #f7f9fc;
  --basic-fill-03-300: #edf1f7;
  --basic-fill-04-400: #e4e9f2;
  --basic-fill-06-600: #8f9bb3;
  --basic-fill-07-700: #2e3a59;
  --basic-fill-08-800: #222b45;
  --basic-fill-09-900: #192038;
  --basic-fill-10-1000: #151a30;

  --setup-1-setup-color: #c9e7e5;
  --color-fill-1-primary-100: #e6eeff;
  --color-fill-1-primary-200: #adc8ff;
  --color-fill-1-primary-300: #83a9ff;
  --color-fill-1-primary-600: #244edb;
  --color-fill-1-primary-700: #1939b7;
  --color-fill-1-primary-800: #0f2793;
  --color-fill-1-primary-900: #091a7a;
  --color-fill-2-success-200: #b3ffd6;
  --color-fill-2-success-300: #8cfac7;
  --color-fill-2-success-400-disable: #8bdbd7;
  --color-fill-2-success-400: #27e2a2;
  --color-fill-2-success-500: #00d59c;
  --color-fill-2-success-600: #00b383;
  --color-fill-2-success-700: #008f72;
  --color-fill-2-success-800: #007566;
  --color-fill-4-warning-300: #fed08e;
  --color-fill-4-warning-700: #b86e00;
  --color-fill-4-warning-800: #945400;
  --color-fill-5-danger-400: #fd97a1;
  --color-fill-5-danger-500: #ea5455;
  --color-fill-5-danger-600: #db3442;
  --color-fill-5-danger-800: #871a37;
  --color-fill-6-others-pink-500: #ff9ca8;
  --color-fill-6-others-yellow-500: #ffd559;
  --color-fill-6-others-purple-500: #8952fc;
  --basic-fill-11-1100-black: #101426;
  --container-dark: #141414;
  --dark: #000000;
  --danger-text: #f64e5f;
}

